.container {
    position: relative;
  }
  
  .bannerArea {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .logoBanner {
    /* width: auto; */
    height: auto  ;
  }
  
  .banner {
    border-radius: 50%;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    margin-top: -480px;
    height: 150px;
    width: 150px;
    background-color: #FFFFFF;
    -webkit-box-shadow: -1px -63px 44px -57px rgba(0, 0, 0, 0.41);
    -moz-box-shadow: -1px -63px 44px -57px rgba(0, 0, 0, 0.41);
    box-shadow: -1px -63px 44px -57px rgba(0, 0, 0, 0.41);
  }
  
  .workArea {
    position: relative;
    /* top: 50%; */
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    margin-top: 7%;
    height: 430px;
    width: 600px;
    background-color: #FFFFFF;
    border-radius: 4px;
    box-shadow: 0 5px 25px 5px rgba(0, 0, 0, 0.4);
  
  }
  
  .content {
    height: 100%;
    width: 100%;
    padding-top: 5%;
  }
  
  .title {
    font-size: 25px;
    display: flex;
    justify-content: center;
    color: #395a8b;
    /* font-family: sans-serif,'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif */


    /* display: flex;
    justify-content: center;
    align-items: center;
    font-size: 100%;
    color: #395a8b;
    font-family: 'SourceSansPro', sans-serif;
    margin-bottom: 2%; */
  }
  
  .formLogin {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
  
  .buttonBox {
    /* margin-left: 5%; */
    padding-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .buttonLogin {
    color: #666666;
    background: linear-gradient(#fafafa, #eee);
    width: 12%;
    height: 40px;
    border-radius: 2px;
    border: 1px 1px solid #c8c8c8;
  }
  
  .labelText {
    font-size: 0.9em;
    text-transform: uppercase;
    line-height: 2.4;
    letter-spacing: 0.3px;
    color: #999;
  }
  
  .inputText {
    display: block;
    display: block;
    font-size: 1.10em;
    padding: 8px;
    border: 1px solid #BBBBBB;
    border-radius: 2px;
    width: 100%;
    transition: box-shadow 0.2s ease-out, border 0.2s;
    width: 100%;
  }
  
  .inputText:focus,
  .inputText:active {
    border: 1px solid blue;
    outline: none;
  }
  
  .footerBox {
    display: flex;
    justify-content: center;
    color: #999999;
    font-size: 12px;
    padding-top: 50px;
  }
  
  .footerBox a:hover {
    text-decoration: underline;
  }
  .modeRow{
    display: flex;
    flex-direction: row;
  }
  
  .errorBox{
    background-color: #FFCCCC ;
    display: flex;
    justify-content: center;
    width: 60%;
    height: 30px;
    margin-left: 20%;
    color: #4C4C4C  ;
    animation: fadeIn 2s ease-out forwards;
  }

  .boxBannerBSM{
    background-color: black;
    width: 100%;
    justify-content: center;
    border: none;
  }
  .logoBSMWhite{
    width: 50%;
    justify-content: center;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }


  

  /* Responsive design part  */

  @media screen and (max-width: 320px) {
    .workArea{
      max-width: 80%;
      margin-top: 20%;
    }
    
    .boxBannerBSM{
      border: 1px solid black;
    }
    
    .buttonBox{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .buttonLogin{
      width: 30%;
      background: linear-gradient(#fafafa28, #eee);
    }
  }
  
  @media screen and (max-width: 600px) {
    
    .workArea{
      max-width: 80%;
      margin-top: 20%;
    }
    
    .boxBannerBSM{
      border: 1px solid black;
    }
    
    .buttonBox{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .buttonLogin{
      width: 30%;
      background: linear-gradient(#fafafa28, #eee);
    }
  }