.backgroundImageModal {
    /* background-color: red; */
    display: flex;
    justify-content: center;
    padding-top: 10%;
    padding-bottom: 20px;
}

.modalCloseBtn {
    margin: 0;
    position: absolute;
    top:0;
    right: 1px;
    font-weight: 600;
     background-color: #cccccc;
     width: 25px;
     height: 25px;
     border-radius: 30px;

    /* width: 30px;
    height: 30px;
    border-radius: 23px;
    background-color: rgb(233, 224, 224);
    color: black;
    opacity: 1;
    z-index: 10;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    top: 20px;
    right: -15px;
    transform: translate(-50%, -50%); */

}

.header {
    font-size: 16px;
    /* padding-left: 6%; */
    /* font-weight: bold; */
    background-color: #000000;
    word-break: keep-all;

    /* padding-right: 6%; */
}

.header:not(:first-child) {
    /* padding-left: 10px; */
    /* margin-left: 10px; */
}

.header:hover {
    background-color: #007dde;
    /* padding-left: 6%;
    padding-right: 6%; */
        /* background-color: aqua; */

}

.headerActive {
    word-break: keep-all;
    font-size: 16px;
    background-color: #007dde;
    /* padding-left: 6%;
    padding-right: 6%; */
    /* padding-left: 10px; */


}

/* .header:active {
    background-color: #000000;
    padding-left: 10px;

} */

.titleQuestions {
    margin-top: 1em;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

.headerBox {
    display: flex;
    color: white;
    font-weight: bold;
    /* background-color: black; */
    justify-content: space-between;
    
    /* color: white;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background-color: #999;;
    height: 2rem; */
}

.boxContent {
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
}

.rowGray {
    width: 100%;
    background-color: #eee;
    margin-bottom: 20px;
    /* margin-bottom: 10px; */
}

.insideGray {
    /* padding-top: 5px;
    padding-bottom: 15px; */
    display: flex;
}

.labelName {
    background-color: black;
    color: white;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 16px;
    min-width: 30%;
    width: max-content;
    padding-right: 10px;
    text-align:left;
    padding-left: 2%;
    padding-top: 1%;
    padding-bottom: 1%;
    /* width: 400px;
    color: #333;
    font-size: 14px;
    font-weight: bold; */
}

.boxQuestions{
    border-bottom: 3px solid black;
    margin-bottom: 20px;
    margin-top: 10px;
    padding-bottom: 30px;
}
textarea:focus, input:focus{
    outline-color: #999;
    /* outline: none; */
    /* height: ; */
}
input,textarea,select{
    width: 100%;

    margin-left: 10px;
    background-color: #f4f4f4    ;
}
.inputBox {
    /* width: 400px; */
    /* background-color: black; */
    /* color: white; */
    text-transform: uppercase;
    font-weight: 900;
    font-size: 16px;
    /* width: 30%; */
    text-align:left;
    /* padding-left: 2%; */
    padding-top: 1%;
    padding-bottom: 1%;
    border: none;
    width: 300px;
    /* width: 400px;
    color: #333;
    font-size: 14px;
    font-weight: bold; */

}

select {
    width: 220px;
}

.centerDiv {
    width: 100%;
    display: flex;
    margin-top: 30px;
    margin-bottom: 30px;
}

.buttonSaveAndContinue {
    margin: 0 auto;
    background: #2fcb43;
    border: 1px solid #2fcb43;
    border-radius: 3px;
    color: white;
    padding: 3px 20px 3px 20px;
    font-weight: bold;
}

.buttonSaveAndContinueDisabled {
    margin: 0 auto;
    border-radius: 3px;
    padding: 3px 20px 3px 20px;
    font-weight: bold;
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
}


/* RESPONSIVE DESING FROM HERE  */

@media screen and (max-width: 320px) {

    .modal{
      width: 90%;
      height: 70%;
  }
  
  }
  @media screen and (max-width: 600px) {
      
    .headerBox{
        /* background-color: red; */
        width: 100%;
    }
    
    .header{
        word-break: keep-all;
        font-size: 12px;
        width: 25%;
    }
    .headerActive{
        word-break: keep-all;
        font-size: 12px;
        width: 25%;
    }
    .insideGray,.labelName{
        /* font-size: 80%; */
    }
  }
  