
body {
    background-color: black;
    margin: 0;
   padding: 0;
}
.modal{
  border-radius: 10px;
    position:fixed;
    top:25%;
    left:50%;
    transform: translate(-50%, -20%);
    width: 50%;
    height:70%;
    z-index:1;
    background-color: #fafafa;
    overflow-y: auto;
        animation-name: animationModal;
    animation-duration: 1s;
     /* transform:scaleY(.01) scaleX(0); */

   
}

@keyframes animationModal {
    0%   { top:50%}
  100% { top:25% }
}


/* RESPONSIVE DESING FROM HERE  */

@media screen and (max-width: 320px) {

  .modal{
    width: 90%;
    height: 70%;
}

}
@media screen and (max-width: 600px) {
    
  .modal{
      width: 90%;
      height: 70%;
  }
}
