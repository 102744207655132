@tailwind base;
@tailwind components;
@tailwind utilities;


  
  html{
    font-family: "Montserrat";
    font-weight: 100;
  }
  