.workArea { 
    background-color: #eee;
    margin: 0;
}
.miniPanelRight{
    background-color: transparent;
    color: black;
    /* border-radius: 0 0 5px 5px; */

  font-size: 0.8em;
  /* padding: 5px 10px; */
  position: absolute;
  right: 20px;
  text-align: right;
  top: 0;
  z-index: 5;
}

.logOut{
    color:#007DDE;
    text-decoration: none;
    cursor: pointer;
}

.buttonCompletedInfo{
  margin-top: 2%;
  margin-bottom: 2%;
  background-color: #fafafa;
  border-color: #999;
  color: #999;
  transition: all 0.3s ease-in-out;
  border: 1px solid;
    border-top-color: currentcolor;
    border-right-color: currentcolor;
    border-bottom-color: currentcolor;
    border-left-color: currentcolor;
  border-radius: 0.1875em;
  cursor: pointer;
  display: inline-block;
  font-size: 1em;
  font-weight: 400;
  line-height: 1;
  padding: .75em 1em;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;

}

.buttonCompleteInfo{
  margin-top: 2%;
  margin-bottom: 2%;
  background-color: #fafafa;
  border-color: #007DDE;
  color: #007DDE;
  transition: all 0.3s ease-in-out;
  border: 1px solid;
    border-top-color: currentcolor;
    border-right-color: currentcolor;
    border-bottom-color: currentcolor;
    border-left-color: currentcolor;
  border-radius: 0.1875em;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  padding: .75em 1em;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;

}

.titleQuestion{
  font-size: 30px;
  font-weight: bold;
}
.imageCoverUp{
  /* this property to set backgroundImage exact like the original version and not full height */
  background-image: url('../../files/coverindex7-1920x1080.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    left: 0;
    position: absolute;
    top: 0;
    z-index: 0;
}

.mainBox{
  margin-top: 10%;
  padding-bottom: 1em;
  position: relative;
  z-index: 1;
}

.boxImage{
  display: flex;
  justify-content: center;
  margin: auto;
  padding: 3%;
  /* background-color:lightgray; */
  /* border-radius: 50%; */
  /* width: 280px; */
  /* opacity: 0.8; */

}
.headerLogoImage{
  padding: 20px 0;;
}

.boxButtonApplication{
  width: 100%;
  height: 20%;
  margin-bottom: 20%;
}
.boxApplicationfirstRow{
  background-color: black;
  /* margin-top: ; */
  margin: 0 auto ;
  width: 60%;
  display: flex;
  height: auto
}

.boxApplication{
  background-color: #fafafa;
  margin: 0 auto ;
  width: 60%;
  display: flex;
  height: auto
}

.leftBox{
  background-color: black;;
  flex-direction: column;
  color: white;
  width: 30%;
}

.rightBox{
  background-color: white;
  width: 70%;

}

.containerBoxRight{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.firstColumn{
  font-weight: 300;
  padding-top: 20%;
  padding-left: 10%;
  font-size: 24px;
  padding-bottom: 5%;
}

.secondColumn{
  border-top: 1px solid white;
  border-bottom: 1px solid white;
}

.modeRow{
  display: flex;
  flex-direction: row;
}
 .profileImage{
  margin-top: 10%;
  margin-bottom: 10%;
  margin-left: 5%;
 }

 .dataContact{
  margin-left: 5%;
  margin-top: 5%;
  width: auto;
  max-width: 50%;
 }

 .file{
  width: 100%;
  height: 0.5rem;
  text-align: center;
  color: white !important;
  background-image: white !important;
  -webkit-appearance: white;
  -moz-appearance: white;
  appearance: white;
  -moz-progress-bar: white !important;
  -webkit-progress-value : white !important;
 }

 .buttonViewApplication{
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  background-color:  #00D8FF;
  color: black;
  height: 100%;
  width: 100%;
  border-radius: 2%;
  font-size: 14px;
 }

 .buttonAddApplication {
  left: 20%;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
 }


 .addNewApplication { 
  margin-top: 30px;
  width: 70%;
  height: 50px;
  margin-bottom: 10%;
  margin-left: 50px;
  background-color: #2fcb43;
  color: white;
  border-radius: 2%;
  position: relative;
 }

 .titleAdmission {
  font-size: 24px;
  font-weight: bold;
  /* padding-left: 25px; */
  padding-top: 30px;
  border-bottom: 3px solid black;
  width: 90%;
  padding-bottom: 25px;
  margin-left: 5%;
  /* background-color: #eee; */


 }
 .contentAdmission{
  background-color: white;
  padding-top: 25px;
  /* padding-left: 25px; */
  margin-left: 5%;
  /* font-size: 30px; */
  /* width: auto; */
 }

 .buttonApplicants{
  min-width: 30%;
  width: auto;
  margin-top: 20px;
  background: #fafafa;
border: 1px solid #007dde;
border-radius: 3px;
color: #007dde;
 }


.provitionalName{
  padding: 0;
  margin: 0;
}
/* CSS */
.buttonApplicants {
  background-color: black;
  border-radius: 3px;
  border: 1px solid #7aa7c7;
  box-shadow: rgba(255, 255, 255, .7) 0 1px 0 0 inset;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,system-ui,"Segoe UI","Liberation Sans",sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.15385;
  margin-left: 5%;
  /* margin: 25px; */
  outline: none;
  padding: 8px .8em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
}

.buttonApplicants:hover,
.buttonApplicants:focus {
  background-color:#36454F	;
  color: white;
}

.buttonApplicants:focus {
  box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
}

.buttonApplicants:active {
  background-color: #a0c7e4;
  box-shadow: none;
  color: #2c5777;
}


.provitionalName{
  margin-left: 0;
}
.provitionalName:last-child {
  margin-bottom: 5%;
}


/* CSS */
.buttonDeleteApplicants {
  background-color: #ff2c2c;
  border-radius: 3px;
  /* border: 1px solid #7aa7c7; */
  /* box-shadow: rgba(255, 255, 255, .7) 0 1px 0 0 inset; */
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,system-ui,"Segoe UI","Liberation Sans",sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.15385;
  outline: none;
  padding: 8px .8em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
}

.buttonDeleteApplicants:hover,
.buttonDeleteApplicants:focus {
  background-color: #de0a26;
  color: white;
}

.buttonDeleteApplicants:focus {
  box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
}

.buttonDeleteApplicants:active {
  background-color: #a0c7e4;
  box-shadow: none;
  color: white;
}



 .questionsBox { 
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 99%;
 }


 .optionalField{
  background-color: #2fcb43;
  color: white;
  font-weight: 400;
  padding: 5px 15px; 
  text-transform: capitalize;
  align-items: center;
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
  /* font-size: 16px;
  font-weight: 600; */
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 1rem;
  /* padding: calc(.875rem - 1px) calc(1.5rem - 1px); */
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
 }
.lastColumnQuestion{
  color: white;
  background-color: #00D8FF;
  font-weight: 400;
  padding: 5px 15px; 
  text-transform: capitalize;
  align-items: center;
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
  /* font-size: 16px;
  font-weight: 600; */
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 1rem;
  /* padding: calc(.875rem - 1px) calc(1.5rem - 1px); */
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
  
}

.newBoxLeft{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.titleHello{
  font-size: 32px;
}

.titleWelcome{
  font-size: 32px;
  font-weight: bold;
}
.boxApplicant{
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 2px solid white;
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 10%;
  padding-right: 10%;
  width: 90%;
}
.boxAddnewApplicant{
  margin-top: 5%;
  margin-bottom: 5%;
  background-color: #2fcb43;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 600;
  border-radius: 2px;
  font-size: 16px;

}

.buttonEmailInfo{
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 0 0 5px 5px;
  font-size: 14px;
  font-weight: 600;
}

.buttonCloseSession{
  background-color: #007dde;
  color: white;
  padding: 10px;
  /* border-radius: 0 0 5px 5px; */
  font-size: 14px;
  font-weight: 600;

}






  /* Responsive design part  */

  @media screen and (max-width: 320px) {
    
    .imageCoverUp{
      width: 100%;
      height: 20%;
    }

    .miniPanelRight{
      width: 100%;
      /* height: 100px; */
      /* font-size: 2px; */
    }
    .buttonEmailInfo{
      font-size: 12px;
      height: 30px;
      padding: 0;
      padding-left: 1%;
      padding-right: 1%;
    }
    .buttonCloseSession{
      font-size: 12px;
      height: 30px;
      padding: 0;
      padding-left: 1%;
      padding-right: 1%;   
      margin: 0;
    }

    .mainBox{
      margin-top: 50px;
    }
    .boxApplication{
      justify-content: center;
      margin-top: 30%;
      text-align: center;
      flex-direction: column;
      /* height: ; */
    }
    .boxApplicationfirstRow{
      margin: 0;
     display: flex;
     justify-content: center;
     width: 100%;
     background-color: transparent;
    }
    
    .boxApplicant{
      padding-top: 2%;
      padding-bottom: 2%;
      padding-left: 5%;
      padding-right: 5%;
      font-size: 80%;
    }

    .boxAddnewApplicant{
      width: 90%;
      font-size: 80%;
      padding: 0;
    }
    .newButtonAddApplicant{
      width: 40%;
    }
    .titleWelcome{
      font-size: 24px;
    }
    .leftBox{
      background-color: black;;
      flex-direction: column;
      color: white;
      flex: 1 0 10em;
    }
    /* PENDING ADJUST THIS */
    /* .mainBox{
      display: none;
    } */

  }

  @media screen and (max-width: 600px) {

    .imageCoverUp{
      width: 100%;
      height: 20%;
    }

    .miniPanelRight{
      width: 100%;
      /* height: 100px; */
      /* font-size: 2px; */
    }
    .buttonEmailInfo{
      font-size: 12px;
      height: 30px;
      padding: 0;
      padding-left: 1%;
      padding-right: 1%;
    }
    .buttonCloseSession{
      font-size: 12px;
      height: 30px;
      padding: 0;
      padding-left: 1%;
      padding-right: 1%;   
      margin: 0;
    }

    .mainBox{
      margin-top: 50px;
    }
    .boxApplication{
      justify-content: center;
      margin-top: 30%;
      text-align: center;
      flex-direction: column;
      /* height: ; */
    }
    .boxApplicationfirstRow{
      margin: 0;
     display: flex;
     justify-content: center;
     width: 100%;
     background-color: transparent;
    }
    
    .boxApplicant{
      padding-top: 2%;
      padding-bottom: 2%;
      padding-left: 5%;
      padding-right: 5%;
      font-size: 80%;
    }

    .boxAddnewApplicant{
      width: 70%;
      font-size: 80%;
      padding: 0;
    }
    .newButtonAddApplicant{
      width: 100%;
    }
    .titleWelcome{
      font-size: 24px;
    }
    .rightBox{
      width: 100%;
      justify-content: center;
      text-align: center;
      align-items: center;
    }
    .leftBox{
      background-color: black;;
      flex-direction: column;
      color: white;
      width: 100%;
    }


    .titleQuestion{
      font-size: 14px;
     }
    
    .boxIscomplete{
      display: flex;
      justify-content: right;
      align-items: right;
      text-align: right;
      width: 100%;
      font-size: 14px;
    }

    .buttonCompleteInfo{
      font-size: 12px;
      
    }
    /* PENDING ADJUST THIS */
    /* .mainBox{
      display: none;
    } */
   
  }